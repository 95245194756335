import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

import * as Rails from "https://cdn.jsdelivr.net/npm/@rails/ujs@7.1.3-4/app/assets/javascripts/rails-ujs.min.js";

import "bootstrap";
import "popper.js";
import "@nathanvda/cocoon";

import "./main.js";
import "./flatpickr.js";

import * as ActiveStorage from "@rails/activestorage";
